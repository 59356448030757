﻿﻿
<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-img
        alt="OCS Logo"
        class="shrink mr-2"
        contain
        src="~@/assets/logo/logo.png"
        transition="scale-transition"
        width="60"
      />
    </div>
    <v-btn to="/">
      Ocarina Championship
    </v-btn>
    <v-spacer />
    <v-btn v-for="route in routes" :key="route.path" @click="() => navigateTo(route.path)">
      {{ route.name }}
    </v-btn>
  </v-app-bar>
</template>
<script>
import { routes } from "@/router"

export default {
  name: "AppTopNavigation",
  computed: {
    routes () {
      return routes.filter(x => x.navBar).map(x => ({
        path: x.path,
        name: x.name
      }))
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    }
  }
}
</script>
<style>
</style>
