export const challengesSeason1 = [
  {
    title: "The Skulltula Hunt",
    rules: "/challenges/season1/C1.jpg",
    clue: null,
    date: "September 18, 2016",
    winner: {
      name: "Amateseru",
      link: "https://www.twitch.tv/amateseru",
    },
    time: "1:37:29",
    recap: "/recap/season1/race1",
    results: "http://www.speedrunslive.com/races/result/#!/180407",
    background: "/challenges/season1/card/1.png",
  }, {
    title: "Glass Ganon",
    rules: "/challenges/season1/C2.jpg",
    clue: "/clue/clue2.mp3",
    date: "October 2, 2016",
    winner: {
      name: "Sniping",
      link: "https://www.twitch.tv/sniping117",
    },
    time: "1:47:12",
    recap: "/recap/season1/race2",
    results: "http://www.speedrunslive.com/races/result/#!/181301",
    background: "/challenges/season1/card/2.png",
  }, {
    title: "Killink Spree!",
    rules: "/challenges/season1/C3.jpg",
    clue: "/clue/Clue3.html",
    date: "October 16, 2016",
    winner: {
      name: "Exodus",
      link: "https://www.twitch.tv/exodus122",
    },
    time: "1:40:16",
    recap: "/recap/season1/race3",
    results: "http://www.speedrunslive.com/races/result/#!/182117",
    background: "/challenges/season1/card/3.png",
  }, {
    title: "Trick or Treat",
    rules: "/challenges/season1/C4.jpg",
    clue: "/clue/Clue4.mp3",
    date: "October 30, 2016",
    winner: {
      name: "Sniping",
      link: "https://www.twitch.tv/sniping117",
    },
    time: "1:29:43",
    recap: "/recap/season1/race4",
    results: "http://www.speedrunslive.com/races/result/#!/182935",
    background: "/challenges/season1/card/4.png",
  }, {
    title: "Not MST",
    rules: "/challenges/season1/C5.jpg",
    clue: "/clue/season1/clue5",
    date: "November 13, 2016",
    winner: {
      name: "Marco",
      link: "https://www.twitch.tv/marco8641",
    },
    time: "1:27:19",
    recap: "/recap/season1/race5",
    results: "http://www.speedrunslive.com/races/result/#!/183772",
    background: "/challenges/season1/card/5.png",
  }, {
    title: "The Janitor Challenge",
    rules: "/challenges/season1/C6.jpg",
    clue: "/clue/Clue6.png",
    date: "November 27, 2016",
    winner: {
      name: "Tob3000",
      link: "https://www.twitch.tv/tob3000",
    },
    time: "1:26:11",
    recap: "/recap/season1/race6",
    results: "http://www.speedrunslive.com/races/result/#!/184600",
    background: "/challenges/season1/card/6.png",
  }, {
    title: "The Downside? ...",
    rules: "/challenges/season1/C7.jpg",
    clue: "/clue/Clue7.mp4",
    date: "December 11, 2016",
    winner: {
      name: "Runnerguy",
      link: "https://www.twitch.tv/runnerguy2489",
    },
    time: "1:09:50",
    recap: "/recap/season1/race7",
    results: "http://www.speedrunslive.com/races/result/#!/185456",
    background: "/challenges/season1/card/7.png",
  }, {
    title: "A Kakariko Kristmas",
    rules: "/challenges/season1/C8.jpg",
    clue: "/clue/season1/clue8",
    date: "December 18, 2016",
    winner: {
      name: "Exodus",
      link: "https://www.twitch.tv/exodus122",
    },
    time: "1:21:41",
    recap: "/recap/season1/race8",
    results: "http://www.speedrunslive.com/races/result/#!/185837",
    background: "/challenges/season1/card/8.png",
  }, {
    title: "The Materialist",
    rules: "/challenges/season1/C9.jpg",
    clue: "/clue9",
    date: "January 22, 2017",
    winner: {
      name: "Exodus",
      link: "https://www.twitch.tv/exodus122",
    },
    time: "1:27:42",
    recap: "/recap/season1/race9",
    results: "http://www.speedrunslive.com/races/result/#!/187615",
    background: "/challenges/season1/card/9.png",
  }, {
    title: "OCS Bingo",
    rules: "/challenges/season1/C10.jpg",
    clue: "/clue/Clue10.mp4",
    date: "February 5, 2017",
    winner: {
      name: "Marco",
      link: "https://www.twitch.tv/marco8641",
    },
    time: "1:28:04",
    recap: "/recap/season1/race10",
    results: "http://www.speedrunslive.com/races/result/#!/188647",
    background: "/challenges/season1/card/10.png",
  }
]

export const challengesSeason2 = [
  {
    title: "Not Like Like This",
    rules: "/challenges/season2/C1.jpg",
    clue: "/clue/S2/C1.png",
    date: "August 27, 2017",
    winner: {
      name: "Amateseru",
      link: "https://www.twitch.tv/amateseru",
    },
    time: "01:17:28",
    recap: "/recap/season2/race1",
    results: "http://www.speedrunslive.com/races/result/#!/202100",
    background: "/challenges/season2/card/1.png",
  },
  {
    title: "DMM",
    rules: "/challenges/season2/C2.jpg",
    clue: "",
    date: "September 3, 2017",
    winner: {
      name: "Marco",
      link: "https://www.twitch.tv/marco8641",
    },
    time: "01:41:38",
    recap: "",
    results: "http://www.speedrunslive.com/races/result/#!/202699",
    background: "/challenges/season2/card/2.jpg",
  }, {
    title: "Greyout Bingo",
    rules: "/challenges/season2/C3.jpg",
    clue: "",
    date: "September 10, 2017",
    winner: {
      name: "Tob3000",
      link: "https://www.twitch.tv/tob3000",
    },
    time: "01:30:42",
    recap: "/recap/season2/quarter1",
    results: "http://www.speedrunslive.com/races/result/#!/203214",
    background: "/challenges/season2/card/3.png",
  }, {
    title: "Rupees dont grow...",
    rules: "/challenges/season2/C4.jpg",
    clue: "/clue/S2/C4.mp3",
    date: "September 24, 2017",
    winner: {
      name: "Amateseru",
      link: "https://www.twitch.tv/amateseru",
    },
    time: "01:20:38",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/204236",
    background: "/challenges/season2/card/4.png",
  }, {
    title: "Trials and Doors",
    rules: "/challenges/season2/C5.jpg",
    clue: null,
    date: "October 1, 2017",
    winner: {
      name: "Exodus",
      link: "https://www.twitch.tv/Exodus122",
    },
    time: "01:22:37",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/204799",
    background: "/challenges/season2/card/5.jpg",
  }, {
    title: "Reverse Bingo",
    rules: "/challenges/season2/C6.jpg",
    clue: null,
    date: "October 8, 2017",
    winner: {
      name: "Exodus",
      link: "https://www.twitch.tv/Exodus122",
    },
    time: "01:38:08",
    recap: "/recap/season2/quarter2",
    results: "http://www.speedrunslive.com/races/result/#!/205397",
    background: "/challenges/season2/card/6.png",
  }, {
    title: "Beyond The Memes",
    rules: "/challenges/season2/C7.jpg",
    clue: "/clue/S2/C7.png",
    date: "October 22, 2017",
    winner: {
      name: "Juwk",
      link: "https://www.twitch.tv/juwk",
    },
    time: "01:39:02",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/206400",
    background: "/challenges/season2/card/7.jpg",
  }, {
    title: "Skulltula Revenge",
    rules: "/challenges/season2/C8.jpg",
    clue: null,
    date: "October 29, 2017",
    winner: {
      name: "Runnerguy",
      link: "https://www.twitch.tv/runnerguy2489",
    },
    time: "01:04:16",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/206898",
    background: "/challenges/season2/card/8.png",
  }, {
    title: "OCS Countdown",
    rules: "/backgrounds/Lost.jpg",
    clue: "https://pastebin.com/PMS6e9Sf",
    date: "November 5, 2017",
    winner: {
      name: "Amateseru",
      link: "https://www.twitch.tv/amateseru",
    },
    time: "01:30:00",
    recap: "/recap/season2/quarter3",
    results: "",
    background: "/challenges/season2/card/9.png",
  },
  {
    title: "Link Odyssey",
    rules: "/challenges/season2/C10.jpg",
    clue: "/clue/S2/C10.jpg",
    date: "November 19, 2017",
    winner: {
      name: "Marco",
      link: "https://www.twitch.tv/marco8641",
    },
    time: "01:20:48",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/208044",
    background: "/challenges/season2/card/10.png",
  },
  {
    title: "Wanted Link",
    rules: "/challenges/season2/C11.jpg",
    clue: null,
    date: "November 26, 2017",
    winner:
      {
        name: "Amateseru",
        link: "https://www.twitch.tv/amateseru",
      },
    time: "01:34:46",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/208600",
    background: "/challenges/season2/card/11.png",
  },
  {
    title: "OCS Bingo",
    rules: "/challenges/season2/C12.jpg",
    clue: null,
    date: "December 3, 2017",
    winner: {
      name: "hyperion64",
      link: "https://www.twitch.tv/hyperion64",
    },
    time: "01:13:49",
    recap: null,
    results: "http://www.speedrunslive.com/races/result/#!/209173",
    background: "/challenges/season2/card/12.png",
  }
]

export const challengesSeason3 = [
  {
    title: "OCS Welcome Back Party",
    rules: "/challenges/season3/C1.jpg",
    clue: "",
    date: "November 15, 2020",
    winner: {
      name: "Juwk",
      link: "https://www.twitch.tv/juwk",
    },
    time: "01:13:20",
    recap: "",
    results: "https://racetime.gg/oot/witty-rupee-9739",
    background: "/challenges/season2/card/12.png",
  },
  {
    title: "OCS Side Dungeons",
    rules: "/challenges/season3/C2.jpg",
    clue: "",
    date: "November 22, 2020",
    winner: {
      name: "Amateseru",
      link: "https://www.twitch.tv/amateseru",
    },
    time: "01:12:35",
    recap: "",
    results: "https://racetime.gg/oot/clumsy-requiem-1067",
    background: "/challenges/season1/card/7.png",
  }, {
    title: "M.V.S.",
    rules: "/challenges/season3/C3.jpg",
    clue: "",
    date: "November 29, 2020",
    winner: {
      name: "Tob3000",
      link: "https://www.twitch.tv/tob3000",
    },
    time: "01:09:02",
    recap: "",
    results: "https://racetime.gg/oot/unlimited-kotake-5024",
    background: "/challenges/season1/card/1.png",
  }
]

