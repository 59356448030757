<template>
  <v-container>
    <div>
      <v-data-table :headers="headers" :items="season3" :items-per-page="100" />
    </div>
  </v-container>
</template>
<script>
import season3 from "@/data/season3-leaderboard.json";
    export default {
        name: "LeaderboardPage",
        data () {
            return {
              season3:season3
            }
        },
        computed: {
          headers () {
            return [
              { text: "Position", align: "start", value: "Position"},
              { text: "Player", value: "User.Name" },
              { text: "pts", value: "Score" },
              { text: "#Races", value: "TotalRaces" },
            ]
          }
        }
    }
</script>
