<template>
  <v-col class="flex-shrink-1">
    <v-row class="flex-shrink-1 justify-center">
      <Challenge v-for="challenge in challenges" :key="challenge.title" :challenge="challenge" />
    </v-row>
  </v-col>
</template>

<script>
import Challenge from "@/views/challenges/components/Challenge"
export default {
  name: "Challenges",
  components: { Challenge },
  props: {
    challenges: {
      type: Array,
      required: true
    }
  }
}
</script>
