<template>
  <v-card class="ma-2" width="230">
    <v-card-title :title="challenge.title" class="text-h6 text-truncate">
      {{ challenge.title }}
    </v-card-title>
    <v-card-subtitle>
      {{ challenge.date }}
    </v-card-subtitle>
    <v-card-text class="py-0">
      <v-col class="py-0">
        <v-row>
          <v-img :src="card" contain height="100" width="150" />
        </v-row>
        <v-row class="mt-2 flex justify-center">
          <v-btn outlined text color="deep-purple accent-4" :href="challenge.winner.link" target="_blank">
            <v-icon small left>
              mdi-twitch
            </v-icon>
            {{ challenge.winner.name }}
          </v-btn>
        </v-row>
        <v-row class="mt-2">
          {{ formattedTime }}
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-dialog v-model="dialog" width="70%">
        <template #activator="{on, attrs}">
          <v-btn text color="deep-purple accent-4" target="_blank" v-on="on" v-bind="attrs">
            rules
          </v-btn>
        </template>
        <v-card>
          <v-img :src="rules" />
        </v-card>
      </v-dialog>
      <v-btn text color="deep-purple accent-4" :href="challenge.results" target="_blank">
        results
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatDuration } from "date-fns";

export default {
  name: "Challenge",
  props: {
    challenge: {
      required: true,
      type: Object,
    },
  },
  computed: {
    card() {
      {
        return require("@/assets" + this.challenge.background);
      }
    },
    rules() {
      return require("@/assets" + this.challenge.rules);
    },
    duration() {
      const segments = this.challenge.time.split(":").map(s => parseInt(s));

      return { hours: segments[0], minutes: segments[1], seconds: segments[2] };
    },
    formattedTime() {

      return formatDuration(this.duration);
    }
  },
  data() {
    return {
      dialog: false
    }
  }
}
</script>
