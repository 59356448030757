import Vue from "vue"
import VueRouter from "vue-router"
import ChallengesPage from "@/views/challenges/ChallengesPage"
import LeaderboardPage from "@/views/Leaderboard/LeaderboardPage";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: () => import("../views/MainPage.vue"),
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: ChallengesPage,
    navBar: true
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    navBar: true
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: LeaderboardPage,
    navBar: true
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export { routes }

export default router
