<template>
  <v-container>
    <v-card :elevation="8">
      <v-tabs centered grow>
        <v-tab
          id="TabTitle" v-for="challenge in challenges"
          :key="challenge.index"
          :href="`#challenge-tab-${challenge.index}`"
        >
          {{ challenge.name }}
        </v-tab>
        <v-tab-item
          v-for="challenge in challenges" :key="`item-${challenge.index}`"
          :value="'challenge-tab-' + challenge.index"
        >
          <Challenges md4 :challenges="challenge.values" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import { challengesSeason1 } from "@/views/challenges/constants"
import { challengesSeason2 } from "@/views/challenges/constants"
import { challengesSeason3 } from "@/views/challenges/constants"
import Challenges from "@/views/challenges/components/Challenges"

const challengeSeasons = [{
  index: 0,
  name: "Season 1",
  values: challengesSeason1
}, {
  index: 1,
  name: "Season 2",
  values: challengesSeason2
}, {
  index: 2,
  name: "Season 3",
  values: challengesSeason3
}]

export default {
  name: "ChallengesPage",
  components: { Challenges },
  computed: {
    challenges: () => challengeSeasons
  }
}
</script>

<style scoped>
</style>
