<template>
  <v-app>
    <AppTopNavigation />
    <video
      class="align-center" style="position: fixed; bottom: 0; right: 0; min-width: 100%; min-height: 100%"
      autoplay muted
      loop src="/static/windmill_v5.mp4"
    />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppTopNavigation from "@/views/app-top-navigation/AppTopNavigation";
import { challengesSeason3 } from "@/views/challenges/constants";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: { AppTopNavigation },
  mounted() {
    const path = localStorage.getItem("path");
    if (path) {
      localStorage.removeItem("path");
      this.$router.push(path);
    }
  },
  data: () => ({

  }),
  methods: {

  }
}
</script>
